import Repository from "./Repository";
import authHeader from "@/services/auth-header";

const resource = "/users";
export default {
  get(page = 1, sort = "", direction = "", filters = [], search = "", perPage) {
    let url = `${resource}?page=${page}`;
    if (sort) url += `&sort=${sort}`;
    if (direction) url += `&sortDirection=${direction}`;
    if (filters.length) url += "&filters=" + JSON.stringify(filters);
    if (search) url += `&search=${search}`;
    if (perPage) url += `&perPage=${perPage}`;
    return Repository.get(url, { headers: authHeader() });
  },

  getUser(userUid) {
    return Repository.get(`${resource}/${userUid}`, { headers: authHeader() });
  },

  getStripeIntent() {
    return Repository.get(`${resource}/setup-intent`, {
      headers: authHeader(),
    });
  },
  setDanId(values) {
    return Repository.post(`${resource}/danid`, values, {
      headers: authHeader(),
    });
  },
  setCoupon(coupon) {
    return Repository.post(
      `${resource}/coupon`,
      { coupon },
      { headers: authHeader() }
    );
  },
  getMe() {
    return Repository.get(`${resource}/me`, { headers: authHeader() });
  },
  updateGenInfo(data, userUid) {
    return Repository.post(`${resource}/${userUid}/general-info`, data, {
      headers: authHeader(),
    });
  },
  delete(userUid) {
    try {
      return Repository.delete(`${resource}/${userUid}`, { headers: authHeader() });
    } catch (error) {
      return error.response;
    }
  },
};

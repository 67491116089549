import {defineStore} from "pinia";

import AuthService from "@/services/auth.service";
import router from "@/router";

import { useToast } from "vue-toastification";
import { i18n } from "@/i18n";
import useBackendFetch from "@/repositories/BackendFetch";

import { useSettingsStore } from "@/store/settings";
import { useDiveStore } from "@/store/dive";
import { useTrainingStore } from "@/store/training";
import { useWearableStore } from "@/store/wearable";
import { useLogbookStore } from "@/store/logbook";

import { RepositoryFactory } from "@/repositories/RepositoryFactory";
const UsersRepository = RepositoryFactory.get("users");

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const useUserStore = defineStore("user", {
  state: () => {
    const diver = {};
    const user = JSON.parse(localStorage.getItem("user"));
    const loggedIn = !!user;
    const route = router.currentRoute.value;
    const isPublic = route.matched.some((record) => record.meta.public);
    /*
     * Se il "name" della route � null, lascia passare tutto, perch� significa che deve ancora processare l'url.
     */
    if (route.name != null && !loggedIn && !isPublic) {
      router.push("/login");
    }
    return {
      user,
      loggedIn,
      diver,
    };
  },
  actions: {
    async login(user) {
      this.resetUser();
      const toast = useToast();

      this.user = await AuthService.login(user).catch((error) => {
        this.resetUser();
        console.error(error);
        toast.error(error.response.data.message);
      });
      if (this.user.user) {
        const settingsStore = useSettingsStore();
        localStorage.setItem("user", JSON.stringify(this.user));
        await settingsStore.get();
        this.loggedIn = true;
        toast.success(i18n.t('login.successfully'));
      }

      return this.user;
    },
    async forgetPassword(user) {
      this.resetUser();
      const toast = useToast();
      const { data, error } = await AuthService.forgetPassword(user).catch((error) => {
        toast.error(error.response.data.message);
      });
      if (data.status !== undefined) {
        toast.success(data.status);
      } else if (!error.value) {
        toast.success(i18n.t('login.forgetPassword'));
        }
      return data.status;
    },
    async resetPassword(user, token) {
      this.resetUser();
      const { data, error } = await useBackendFetch("/reset-password")
        .post({
          email: user.email,
          password: user.password,
          password_confirmation: user.confirmPassword,
          token: token,
        })
        .json();

      const toast = useToast();
      if (!error.value) {
        toast.success(i18n.t('login.resetPassword'));
        router.push("/login");
      } else {
        toast.error(i18n.t('notification.GenericError'));
        console.log(error);
      }
      return { data, error };
    },
    async logout() {
      try {
        const response = await AuthService.logout();

        const logbookStore = useLogbookStore();
        const diveStore = useDiveStore();
        const wearableStore = useWearableStore();
        const trainingStore = useTrainingStore();
        this.loggedIn = false;
        logbookStore.$reset();
        diveStore.$reset();
        wearableStore.$reset();
        trainingStore.$reset();
        this.resetUser();
        window.location.href='/login';

        const toast = useToast();
        toast.success(i18n.t('logout.successfully'));

        return response;
      } catch (e) {
        console.log(e);
        if (e.response.status === 401 || e.response.status === 405) {
          console.log("Reset session, you will be redirecting to login page.");
          this.resetUser();
          window.location.href='/login';
        }
      }
    },
    resetUser() {
      this.user = null;
      localStorage.removeItem("user");
      this.loggedIn = false;
    },
    async register(user) {
      this.resetUser();
      const toast = useToast();
      const result = await useBackendFetch("signup").post(user).json();

      if (result.statusCode.value >= 200 && result.statusCode.value < 300) {
        toast.success(i18n.t('signup.successfully'));
      } else {
        toast.error(i18n.t(result.data.value.message));
      }

      return result;
    },
    hasRole(role) {
      const u = this.user;
      let found = false;
      role.forEach(function (r) {
        if (u && u.user.roles) {
          if (
            u.user.roles.some((e) => e.slug.toLowerCase() === r.toLowerCase())
          )
            found = true;
        }
      });
      return found;
    },
    hasActiveSubscription() {
      const u = this.user;
      if (u && (u.user.subscription_type || this.isAbleTo("admin_permission")))
        return true;
      return false;
    },
    hasSubscriptions(subscriptions) {
      if (!subscriptions) return true;
      if (this.isAbleTo("admin_permission")) return true;
      const u = this.user;
      if (!u || !u.user) return false;
      if (Array.isArray(subscriptions)) {
        let found = false;

        subscriptions.every(function (a) {
          if (u && u.user.subscription_type) {
            found = u.user.subscription_type.includes(a);

            return false;
          }
        });
        return found;
      } else if (this.user.user.subscription_type) {
        return this.user.user.subscription_type.includes(subscriptions);
      }
      return false;
    },
    /**
     *
     * @param ability Se viene passato un array verifica se possiede almeno un permesso.
     * @returns {*|boolean}
     */
    isAbleTo(ability) {
      if (!ability) return true;
      const u = this.user;
      if (!u || !u.user) return true;
      if (Array.isArray(ability)) {
        let found = false;
        ability.forEach(function (a) {
          if (u && u.user.session_permissions) {
            if (
              u.user.session_permissions.some(
                (e) => e.toLowerCase() === a.toLowerCase()
              )
            )
              found = true;
          }
        });
        return found;
      } else if (this.user.user.session_permissions)
        return this.user.user.session_permissions.includes(ability);
      return false;
    },
    async updateActiveSubscription(subscription) {
      if (this.user) {
        this.user.active_subscription = subscription;
        await this.getMe();
      }
    },
    async setDanId(values) {
      try {
        return await UsersRepository.setDanId(values);
      } catch (error) {
        if (error.response.status === 401) {
          await this.logout();
        }
        return error.response;
      }
    },
    async setCoupon(coupon) {
      try {
        return await UsersRepository.setCoupon(coupon);
      } catch (error) {
        if (error.response.status === 401) {
          await this.logout();
        }
        error.response;
      }
    },
    async getMe() {
      try {
        const { data } = await UsersRepository.getMe();
        this.user.user = data;
        localStorage.setItem("user", JSON.stringify(this.user));
      } catch (error) {
        if (error.response.status === 401) {
          await this.logout();
        }
        return error.response;
      }
    },
    async getUser(id) {
      try {
        const { data } = await UsersRepository.getUser(id);
        this.diver = data;
      } catch (error) {
        if (error.response.status === 401) {
          await this.logout();
        }
        return error.response;
      }
    },
    async updateGenInfo(values, userId) {
      try {
        const {data} = await UsersRepository.updateGenInfo(values, userId);
        this.user.user = data;
        localStorage.setItem("user", JSON.stringify(this.user));
        this.loggedIn = true;
      } catch (error) {
        if (error.response.status === 401) {
          await this.logout();
        }
        console.error(error)
        return error.response;
      }
    },
    async updateGenInfoUser(values, userId) {
      try {
        const {data} = await UsersRepository.updateGenInfo(values, userId);
        return data;
      } catch (error) {
        if (error.response.status === 401) {
          await this.logout();
        }
        console.error(error)
        return error.response;
      }
    },
  },
});
